<template>
  <b-card title="Scripts" class="col-12" v-if="scripts">
     <b-table striped hover :items="scripts" :fields="fields">
       <template v-slot:cell(last_run)="data">
         {{ moment(data.item.last_run*1e3).fromNow() }}
       </template>
       <template v-slot:cell(status)="data">
         <div class="ticons" v-if="data.item.status=='error'"><v-icon name="alert-octagon" class="red" /></div>
         <div class="ticons" v-if="data.item.status=='running'"><v-icon name="play-circle" class="green" /></div>
         <div class="ticons" v-if="data.item.status=='done'"><v-icon name="check" /></div>
       </template>
       <template v-slot:cell(next_run)="data">
         {{ data.item.next_run ? moment(data.item.next_run*1e3).calendar(): null }}
       </template>
       <template v-slot:cell(icons)="data">
         <a href="#" v-if="data.item.status!='running'" class="ticons float-left" @click="run(data.item.script_id)"><v-icon name="play" /></a>
         <router-link :to="{ name: 'ScriptRun', params: {script_id:  data.item.script_id}}" class="ticons float-left"><v-icon name="list" /></router-link>
         <a href="#" class="ticons float-left" @click="modalInfo=data.item;modalShow=true"><v-icon name="clock" /></a>
        </template>
     </b-table>
     <!-- Modal to update script next run -->
     <b-modal v-if="modalInfo" id="modal-next_time" @ok="updateNextTime" @cancel="modalInfo=null" :title="'Timer update for '+modalInfo.name" v-model="modalShow">
      Possible values:
       <ul>
         <li>Number to run as interval in minues. E.g. "10" to run every 10 minutes</li>
         <li>hh:mm to run on particular time. E.g. "10:00" to run every morning at 10:00</li>
       </ul>
       <b-form-input v-model="modalInfo.next_time" type="text" />
     </b-modal>
  </b-card>
</template>

<script>
import moment from 'moment'

export default {
  data(){return{
    scripts: null,
    fields: [
      {label:'Id',key:'script_id'},
      {label:'Name',key:'name'},
      {label:'Notes',key:'notes'},
      {label:'Last run',key:'last_run'},
      {label:'Next run',key:'next_run'},
      {label:'Status',key:'status'},
      {label:' ',key:'icons'}
    ],
    moment: moment,
    timer: null,
    modalShow: false,
    modalInfo: null
  }},
  methods: {
    run(id){
      this.scripts = []
      const newdate = Math.floor(new Date().getTime()/1000)
      this.backend.post("/scripts/",{script_id: id, next_run: newdate}).then(resp => 
      {
        if (resp.data)
         {
           this.scripts = resp.data;
           return
         }
      });
    },
    load_scripts(){
     this.backend.get("/scripts/").then(resp => 
      {
        if (resp.data)
         {this.scripts = resp.data;}
        
      });
    },
    updateNextTime(){
      this.backend.post("/scripts/",{script_id: this.modalInfo.script_id, next_time: this.modalInfo.next_time});
    }
  },
  created(){
   this.load_scripts()
   this.timer=setInterval(this.load_scripts,20000)
  },
  beforeDestroy(){
    clearInterval(this.timer)
  }
}
</script>

